import { TASK_STATUS } from './taskConstants';

export const getTaskState = (task, allowMissingInfoTransfer = false) => {
  if (task?.order.planning_status === 'transfer_planned') {
    return TASK_STATUS.transfer_planned;
  }
  if (task?.task_group?.state === 'unassigned' && task?.task?.associated_task_id) {
    return TASK_STATUS.reported;
  }

  if (task?.task?.state === TASK_STATUS.invalidated && task?.task?.cancelled_time) {
    return TASK_STATUS.cancelled;
  }

  if (task?.missing_info && !allowMissingInfoTransfer) {
    return TASK_STATUS.missing_info;
  }

  return task?.task?.state === TASK_STATUS.completed ? TASK_STATUS.completed : task?.task_group?.state;
};
